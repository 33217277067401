import React, { useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import img1 from "../images/contact_message_icon.png";
import img2 from "../images/mail_icon.png";
import img3 from "../images/call_icon.png";
import img4 from "../images/location_icon.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Check if name is empty
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    // Check if email is empty or not in valid format
    if (!formData.email.trim() || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Valid email is required';
      valid = false;
    }

    // Check if phone is empty or not in valid format
    if (!formData.phone.trim() || !/^\+?[0-9]{8,}$/.test(formData.phone)) {
      newErrors.phone = 'Valid phone number is required';
      valid = false;
    }

    // Check if message is empty
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/create/getintouch`, formData);

        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        toast.success("Message sent successfully!");
      } catch (error) {
        console.error('Error sending message:', error);
        toast.error("Failed to send message. Please try again later.");
      }
    } else {
      toast.error("Please fill in the required fields correctly.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="bred_crumb">
        <div className="container">
          <div className="bred_text section_title">
            <h2>Contact us</h2>
            <p className="mb-3 mt-3">
              If you have an query, please get in touch with us, we will revert
              back quickly.
            </p>
            <ul className="d-inline-flex mb-3">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>»</span>
              </li>
              <li>
                <Link to="#">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="contact_page_section">
        <div className="container">
          <div className="row contact_inner">
            <div className="col-6 contact_form ">
              <div className="section_title ">
                <h2>
                  <span>Leave a message</span>
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="form-control text-dark"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <div className="error text-danger">{errors.name}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control text-dark"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <div className="error text-danger">{errors.email}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone number"
                    className="form-control text-dark"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && <div className="error text-danger">{errors.phone}</div>}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control text-dark"
                    name="message"
                    placeholder="Your message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {errors.message && <div className="error text-danger">{errors.message}</div>}
                </div>
                <div className="form-group mb-0">
                  <button
                    type="submit"
                    className="btn puprple_btn"
                    id="submitButton"
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </form>
            </div>
            <div className="col-6 contact_info mt-5 p-5">
              <div className="icon">
                <img src={img1} alt="image" />
              </div>
              <div className="section_title">
                <h3>Have any question?</h3>
                <p className="mt-2">
                  If you have any question about our product,
                </p>
              </div>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src={img2} alt="image" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <Link to="mailto:scanncyindia@gmail.com">
                      scanncyindia@gmail.com
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src={img3} alt="image" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <Link to="tel:+91 8881636363">+91 8881636363</Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src={img4} alt="image" />
                  </div>
                  <div className="text">
                    <span>Visit Us</span>
                    <p>214-Saffron Complex,Fatehgunj,Vadodara-390002</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ContactUs;
