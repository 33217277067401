import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { useAuth } from "../context/AuthContext";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState('buy');
  const [portfolio, setPortfolio] = useState([]);
  const [buyData, setBuyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1)
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchPortfolioData = async () => {
    setLoading(true);
    try {
      const sell_response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/get/currency-sell/${user.customerId}`);
      const buy_response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/buy-currency/${user.customerId}`);
      setPortfolio(sell_response.data.data);
      setBuyData(buy_response.data.data);
      
    } catch (error) {
      setError('Failed to fetch data');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user && user.customerId) {
      fetchPortfolioData(user.customerId);
    }
  }, [user]);

  return (
    <>
      <Header />
      <div className="top_part mb-3 container">
              <Link to="/" onClick={handleBack} className="back_btn">
                <IoMdArrowRoundBack />
                Go Back
              </Link>
            </div>
      <div className="portfolio container" style={{background:'#181a22',borderRadius:'12px',padding:'20px'}}>
        <h1 className='text-center mb-4  '>My Portfolio</h1>
        <div className="tabs fs-5">
          <button
            className={activeTab === 'buy' ? 'active' : ''}
            onClick={() => handleTabChange('buy')}
          >
            Buy
          </button>
          <button
            className={activeTab === 'sell' ? 'active' : ''}
            onClick={() => handleTabChange('sell')}
          >
            Sell
          </button>
        </div>
        <div className="tab-content  p-4" style={{background:'#fff',borderRadius:'12px'}}>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : activeTab === 'buy' ? (
            buyData.length === 0 ? (
              <h4 className='text-dark text-center'>No data available</h4>
            ) : (
            <div style={{background:'#FFFED7'}}>
              <table className="table" style={{background:'#fff'}} >
                <thead>
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">Currency Prefix</th>
                    <th scope="col">Currency Number</th>
                    {/* <th scope="col">Face Value</th> */}
                  </tr>
                </thead>
                <tbody>
                  {buyData.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.currencycharacter}</td>
                      <td>{item.currencynumber}</td>
                      <td>{item.FaceValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )
          ) :  (
            portfolio.length === 0 ? (
              <h4 className='text-dark text-center'>No data available</h4>
            ) :(
            <div>
              <table className="table" style={{background:'#fff',borderRadius:'12px'}}>
                <thead>
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">Currency Prefix</th>
                    <th scope="col">Currency Number</th>
                    <th scope="col">Face Value</th>
                    <th scope="col">Base Value</th>
                    <th scope="col">Value +</th>
                  </tr>
                </thead>
                <tbody>
                  {portfolio.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.Currencycharacter}</td>
                      <td>{item.CurrencyNo}</td>
                      <td>{item.FaceValue}</td>
                      <td>{item.BaseValue}</td>
                      <td>{item.ValuePlus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
