import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import testi1 from '../images/testimonial.jpeg.jpg';
import smiley from '../images/smily.png.png';

export const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    // Function to fetch reviews from the API
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/allReviews`);
        // Assuming your API returns data in the format of mockReviews
        setReviews(response.data.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    // Call the fetchReviews function when the component mounts
    fetchReviews();
  }, []);

  return (
    <>
      <section className="review_section row_am">
        <div className="container">
          <div className="positive_inner">
            <div className="row">
              <div className="col-md-6 sticky-top">
                <div className="sidebar_text aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                  <div className="section_title text-left">
                    <span className="title_badge">Reviews</span>
                    <h2>
                      <span>Positive reviews </span> <br />
                      of our clients
                    </h2>
                  </div>
                  <div className="smiley_icon"><img src={smiley} alt="image" /></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="review_side">
                  {reviews && reviews.map(review => (
                    <div key={review._id} className="review_block" data-aos="fade-up" data-aos-duration={1500}>
                      <div className="coustomer_info">
                        <div className="avtar">
                        <img src={review.profileImage ? `${process.env.REACT_APP_API_URL}/${review.profileImage}` : testi1} alt="image" />

                          <div className="text">
                            <h3>{review.name}</h3>
                          </div>
                        </div>
                        <div className="star">
                          {Array.from({ length: 5 }, (_, index) => (
                            <span key={index}><FaStar /></span>
                          ))}
                        </div>
                      </div>
                      <p>{review.message}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
