import React, { useEffect, useState } from 'react'
import image from '../images/blue_dotes.png.png'
import image1 from '../images/features.jpg.jpg'
import image2 from '../images/bigstar.png.png'
import image3 from '../images/smallStar.png'
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext';
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import ScrollArrow from '../components/ScrollArrow'
import Loader from '../components/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MobileHeader } from '../components/MobileHeader'
import axios from 'axios';
export const Buy_Currency = () => {
    const [currencyNumberPrefix, setCurrencyNumberPrefix] = useState('');
    const [currencyNumber, setCurrencyNumber] = useState('');
    const [sellerData, setSellerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const { user } = useAuth();
    
    useEffect(() => {
      // Simulate data loading delay (remove this in your actual code)
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);
  
      return () => clearTimeout(timer);
    }, []);
   
    const handleSearch=async()=>{
        try {
            // Construct the data object to send to the API
            const formData = {
                costomerId:user.customerId,
                Name:user.name,
                email:user.email,
                contactno:user.mobile_no,
                currencycharacter: currencyNumberPrefix,
                currencynumber: currencyNumber,
                SellerName:sellerData.CostomerName,
                SellerEmail:sellerData.CostomerEmail,
                SellerNumber:sellerData.CostomerNumber,
                facevalue:sellerData.FaceValue,

                // Add other fields as needed
            };
    
            // Send the form data to the API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/contact`, formData);
    
            setShowModal(false)
            setCurrencyNumber('')
            setCurrencyNumberPrefix('')
            toast.success("Data Submited Successfully Our Executive will contact you soon..");
                // setToastDisplayed(true);
 
            
        } catch (error) {
            console.error('An error occurred:', error);
            // You can handle error behavior here (e.g., show an error message)
            toast.error("An error occurred. Please try again later.");
        }
    }

    const handleCancel=async()=>{
        try {
            // Construct the data object to send to the API
            const formData = {
                costomerId:user.customerId,
                Name:user.name,
                email:user.email,
                contactno:user.mobile_no,
                pincode:user.pincode,
                currencycharacter: currencyNumberPrefix,
                currencynumber: currencyNumber
                // Add other fields as needed
            };
    
            // Send the form data to the API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add/currency-buyer`, formData);
    
           
            // You can handle success behavior here (e.g., show a success message)
            toast.success("Data Submited Successfully");
            setCancelModal(false)
            setCurrencyNumber('')
            setCurrencyNumberPrefix('')
           
 
            
        } catch (error) {
            console.error('An error occurred:', error);
            // You can handle error behavior here (e.g., show an error message)
            toast.error("An error occurred. Please try again later.");
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            // Construct the data object to send to the API
            const formData = {
                prefix: currencyNumberPrefix,
                number: currencyNumber
                // Add other fields as needed
            };
    
            // Send the form data to the API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/search-currency`, formData);
    
           
            // You can handle success behavior here (e.g., show a success message)
            if(response.data.match===true){
                setSellerData(response.data.currency)
                setShowModal(true);
            }else{
            setCancelModal(true)

            }
        } catch (error) {
            console.error('An error occurred:', error);
            // You can handle error behavior here (e.g., show an error message)
            toast.error("An error occurred. Please try again later.");
        }
    };
    
    return (
        <>
         {loading && <Loader />}
         <ToastContainer />
         <MobileHeader/>
        <Header/>
        <ScrollArrow/>
            <div class="full_bg">

                <section className="signup_section">
                    <div className="container">
                    <div className="top_part mt-5">
                            <Link to="/" className="back_btn"><IoMdArrowRoundBack />Go Back</Link>
                           
                        </div>
                        {/* Sign In Form */}
                        <div className="form_block">
                            <div className="form_side">
                                <div className="section_title">
                                    <h2><span>Search</span> and claim</h2>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mb-5 d-flex align-items-center" >
                                        <label>Currency-Number*</label>
                                        <input maxLength={3} style={{ width: '15%' }} type="text" className="form-control ms-3" placeholder="XXX" value={currencyNumberPrefix} onChange={(e) => setCurrencyNumberPrefix(e.target.value.toUpperCase())} required />
                                        <input maxLength={6} type="text" className="form-control w-25 ms-3" placeholder="123456" value={currencyNumber} onChange={(e) => setCurrencyNumber(e.target.value)} required />
                                    </div>
                                    <div className="btn_block">
                                        <button type="submit" className="btn puprple_btn ml-0">Submit</button>
                                        <div className="btn_bottom" />
                                    </div>
                                </form>
                            </div>
                            <div className="side_screen">
                                <div className="dotes_blue"><img src={image} alt="image" /></div>
                                <div className="left_icon">
                                    <img src={image3} alt="image" />
                                </div>
                                <div className="right_icon">
                                    <img src={image2} alt="image" />
                                </div>
                                <div className="scrren">
                                    <img src={image1} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                

            </div>
            <Footer/>
            {cancelModal && (
                <div className="modal-1" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Search Result</h5>
                                <button type="button" className="btn-close" onClick={() => setCancelModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                               <span>Sorry Your Currency Number Not Matched Now</span>
                               <h6>In Future When Currency Available We will Contact You..</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCancel}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModal && (
                <div className="modal-1" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Search Result</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                               <span>Your Currency matched </span>
                               <h6>Do you want to buy?</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>No</button>
                                <button type="button" className="btn btn-secondary" onClick={handleSearch}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
