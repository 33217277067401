import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import phoneImage1 from "../images/step 1.png";
import phoneImage2 from "../images/step 1_0.png";
import phoneImage3 from "../images/step3.png";
import phoneImage4 from "../images/step_4_1.png";
import phoneImage5 from "../images/new_step5.png";
import earn from "../images/Earn.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSpring, animated } from "react-spring";
import { MdQrCodeScanner } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export const HomeSlider = () => {
  const { isLoggedIn } = useAuth();
  const sliderImages = [
    phoneImage1,
    phoneImage2,
    phoneImage3,
    phoneImage4,
    phoneImage5,
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,

    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992, // Breakpoint for medium devices
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 913, // Breakpoint for small devices
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 450, // Breakpoint for small devices
        settings: {
          slidesToShow: 2,
        },
      },
      // Add more breakpoints as needed
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust duration as needed
      once: true, // Scroll animation occurs only once
    });
  }, []);
  const springProps = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
  });

  return (
    <>
      <section className="banner_section">
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="banner_text">
                <h1><span>Scan</span> <MdQrCodeScanner /> <span>| Value</span> <GiMoneyStack /> <span>| Earn</span> <img src={earn} alt="" /></h1>

                <p>
                  Each Currency Note No. Is Unquie & Lucky No. For Some One...
                </p>
                <p className="bner-txt">
                  Let's Scan - Value - Match &amp; Earn...
                </p>
               {!isLoggedIn && (
                <div className="btn_block white_btn left_space">
                  <Link to="/register" className="btn  puprple_btn ml-0 ">
                    Register Now
                  </Link>
                  <div className="btn_bottom" />
                </div>
               )}
                
              </div>
            </div>
          </div>

          <div className="hero_slider md_screen mt-5" data-aos="zoom-in">
            <animated.div style={springProps}>
              <Slider {...settings}>
                {sliderImages.map((image, index) => (
                  <div key={index} className="slider-item">
                    <img src={image} alt={`image${index}`} />
                  </div>
                ))}
              </Slider>
            </animated.div>
          </div>
        </div>
      </section>
    </>
  );
};
