import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoEyeOff,IoEye } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [OTP, setOtp] = useState("");
  const [otpField, setOtpField] = useState(false);
  const [passwordField, setPasswordField] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassError, setConfirmPassError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate()
  const validateEmail = () => {
    setEmailError(email ? "" : "Email is required");
  };
  const validatePassword = () => {
    if (!password) {
      setConfirmPassError("Password is required");
    } else if (password !== confirmPass) {
      setConfirmPassError("Passwords do not match");
    } else {
      setConfirmPassError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const verifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/get-OTP`,
        { email, OTP }
      );

      if (response.data.isOk) {
        toast.success("OTP Successfully Verified");
        setPasswordField(true);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const changePassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password`,
        { email, password }
      );

      if (response.data.isOk) {
        toast.success("Password Changed Successfully");
        setLoginSuccess(true)
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  useEffect(() => {
    if (loginSuccess) {
        setTimeout(() => {
            navigate('/login');
           
        }, 3000); // 3000 milliseconds = 3 seconds
    }
}, [loginSuccess, navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/add-OTP`,
          { email }
        );

        if (response.data.isOk) {
          toast.success("OTP Successfully Sent to Email");
          setOtpField(true);
        } else {
          setEmailError('Please Add Registered Email Address');
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="full_bg">
        <section className="signup_section">
          <div className="container">
            <div className="top_part mt-5">
              <Link to="/" className="back_btn">
                <IoMdArrowRoundBack />
                Go Back
              </Link>
            </div>

            <div className="form_block form-block-forgot">
              <div className="form_side form_side_forgot ">
                <div className="section_title">
                  <span className="title_badge">Welcome Go Back</span>
                  <h2>
                    <span>Forgot Password</span>{" "}
                  </h2>
                  <p>Enter Your Registered Email to Reset Password</p>
                </div>
                <form onSubmit={handleSubmit}>
                  {!passwordField && (
                    <>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={validateEmail}
                        />
                        {emailError && (
                          <div className="error-message text-danger">
                            {emailError}
                          </div>
                        )}
                      </div>
                      {otpField && (
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={OTP}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                      )}
                      {!otpField && (
                        <div className="btn_block">
                          <button
                            type="submit"
                            className="btn puprple_btn ml-0"
                          >
                            Get OTP
                          </button>
                        </div>
                      )}

                      {otpField && (
                        <div className="btn_block">
                          <button
                            type="button"
                            onClick={verifyOtp}
                            className="btn puprple_btn ml-0"
                          >
                            Verify
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {passwordField && (
                    <>
                      <div className="form-group d-flex ">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Create New Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={validatePassword}
                        />
                         <button
                          type="button"
                          className="toggle-password border-0 bg-transparent"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ?   <IoEye />:<IoEyeOff />}
                        </button>
                      </div>
                      <div className="form-group d-flex">
                        <input
                         type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm Password"
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                          onBlur={validatePassword}
                        />
                          <button
                          type="button"
                          className="toggle-password border-0  bg-transparent"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ?   <IoEye />:<IoEyeOff />}
                        </button>
                        {confirmPassError && (
                          <div className="error-message text-danger">
                            {confirmPassError}
                          </div>
                        )}
                      </div>
                      <div className="btn_block">
                        <button
                          type="button"
                          onClick={changePassword}
                          className="btn puprple_btn ml-0"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
