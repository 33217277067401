import React from "react";
import logo from "../images/logo.png.png";
import payment from "../images/american-expres.png";
import visa from "../images/visa.png";
import mastercard from "../images/mastercard-logo.png";
import mestro from "../images/mestro-logo.png";
import rupay from "../images/Rupay-Logo.png";
import upi from "../images/upi.png";
import rbank from "../images/rbi.png";
import startup from "../images/startup-white.png";
import makeinindia from "../images/makeinindia.png";
import { FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <>
      <footer
        className="white_text aos-init aos-animate"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6  col-12 col-sm-6">
              <Link className="navbar-brand mb-4" to="/">
                <img className="footer-img" src={logo} alt="Logo" />
              </Link>
              <Link className="mt-4" to="mailto:info@greenpista.com">
                scanncyindia@gmail.com
              </Link>
              <ul className="social_media">
                <li>
                  <Link to="#">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FaInstagram />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FaLinkedin />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FaYoutube />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="links useful">
                <h3>Useful Links</h3>

                <ul>
                  <li>
                    <Link to="#" />
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">About us</Link>
                  </li>
                  <li>
                    <Link to="#" target="_blank">
                      How it Works
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://indiankanoon.org/doc/67061428/?type=print"
                      target="_blank"
                    >
                      Legal: High Court Order
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="links">
                <h3>Help &amp; Support</h3>
                <ul>
                  <li>
                    <Link to="/contact-us">Support / Contact Us</Link>
                  </li>
                  <li>
                    <Link to="#">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Returns &amp; Refunds</Link>
                  </li>
                  <li>
                    <Link to="#">Shipping Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="payment_section">
                <h3>
                  <span> Payment &amp; Accreditation</span>
                </h3>
                <ul className="pay_btn">
                  <li>
                    <Link to="#">
                      <img src={mastercard} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={visa} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={payment} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={mestro} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={rupay} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={upi} alt="image" />
                    </Link>
                  </li>
                </ul>
                <div className="spacer-footer" />
                <div>
                  <p className="footer-sec" style={{ whiteSpace: "nowrap" }}>
                    <img src={rbank} alt="Image 3" className="rbi-logo" />{" "}
                    Certified Notes Only
                  </p>
                  <img
                    src={makeinindia}
                    alt="Image 1"
                    className="makein-india-logo"
                  />
                  <img src={startup} alt="Image 2" className="startup-logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="copyright-p">
                  Copyright © 2024&nbsp;{" "}
                  <Link to="#" style={{ color: "yellow" }}>
                    scanncy.com
                  </Link>
                  &nbsp; All Rights Reserved&nbsp;&nbsp;|&nbsp;&nbsp;Designed By{" "}
                  <span style={{ color: "yellow" }}>SONI-INDIA INFOTECH</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
