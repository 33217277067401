import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MobileHeader } from "../components/MobileHeader";
import { Header } from "../components/Header";
import ScrollArrow from "../components/ScrollArrow";
import { Footer } from "../components/Footer";
import Loader from "../components/Loader";

const PrivacyPolicy = () => {

  const [content,setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/api/auth/get/allCms`)
    .then((res)=>{
      let htmlContent = res.data.allCms;
      htmlContent = htmlContent.filter((item)=>item.title == "Privacy Policy")
      setContent(htmlContent[0].description);

    })
    .catch((err)=>{
      console.error(err)
    })
    setLoading(false);
  })
  return (
    <>
              {loading && <Loader />}
      <MobileHeader />
      <Header />
      <ScrollArrow />
      <section
        className="row_am pricing_section white_text aos-init aos-animate"
        data-aos-duration={1500}
      >
         <div className="pricing_inner  pt-5 privacy-policy">
          <div className="container">
         <span dangerouslySetInnerHTML={{ __html: content }}></span>
         </div>
         </div>
         </section>
      <Footer />
    </>
  )
}

export default PrivacyPolicy