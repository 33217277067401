import React from "react";
import logo from "../images/logo.png.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
export const Header = () => {
  const { isLoggedIn, setIsLoggedIn, user } = useAuth();

  const navigate = useNavigate();
  const handleLogout = () => {
    // Perform logout actions, such as clearing the token from local storage
    localStorage.removeItem("scanncyToken");
    setIsLoggedIn(false);
    // Redirect to the login page after logout
    navigate("/");
  };

 
  return (
    <>
      <header className="desk-header">
        <div className="container">
          <nav className="navbar navbar-expand-lg ">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span className="toggle-wrap">
                  <span className="toggle-bar" />
                </span>
              </span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto text-white">
                <li className="nav-item  ">
                  <Link className="nav-link text-white" to="/">
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link text-white" to="/my-portfolio">
                    Portfolio
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/services">
                    Services
                  </Link>
                </li>
              
                <li className="nav-item">
                  {!isLoggedIn && (
                    <div className="btn_block">
                      <Link className="nav-link dark_btn" to="/login">
                        Login / Signup
                      </Link>
                      <div className="btn_bottom" />
                    </div>
                  )}
                  {isLoggedIn && (
                    <>
                     <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle text-white"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                {user && (user.name)}
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        className="dropdown-item"
                        activeClassName=""
                        to="/user-profile"
                      >
                        My Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="dropdown-item"
                        activeClassName=""
                        to="/"
                        onClick={handleLogout}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
                    
                    </>

                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
