import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("scanncyToken");
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/auth/get/logged-in-user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(response.data.data);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        setIsLoggedIn(false);
        console.error("Error fetching user data:", error);
        // Handle error or set appropriate state based on your application's logic
      }
    };

    fetchUserData();
  }, []);

  // Function to log in the user
  const login = async (userData) => {
    try {
      setUser(userData);
      setIsLoggedIn(true);
      // Store token in localStorage upon successful login
      localStorage.setItem("scanncyToken", userData.token);
    } catch (error) {
      console.error("Error logging in:", error);
      // Handle error or set appropriate state based on your application's logic
    }
  };

  // Function to log out the user
  const logout = () => {
    setUser(null);
    setIsLoggedIn(false);
    // Clear token from localStorage upon logout
    localStorage.removeItem("scanncyToken");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, user, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
