import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSpring, animated } from "react-spring";
import ScrollArrow from "../components/ScrollArrow";
import Loader from "../components/Loader";
import { MobileHeader } from "../components/MobileHeader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
export const Services = () => {
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [sellCurrencies, setSellCurrencies] = useState([]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    // Simulate data loading delay (remove this in your actual code)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
  sellReqData();
    fetchData();
  }, []);
  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get/currency-buyer`)
      .then((response) => {
        // Update state with fetched currencies
        setCurrencies(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const sellReqData=()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/api/auth/all/getCurrencySell`)
    .then((res)=>{
      setSellCurrencies(res.data)
      setLoading(false);
    })
    .catch((error)=>{
      console.error("Error fetching data:", error);
      setLoading(false);
    })
  }
  
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust duration as needed
      once: true, // Scroll animation occurs only once
    });
  }, []);
  const springProps = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
  });
  const handleComingSoonClick = (itemName) => {
    toast.info(`${itemName} feature is coming soon!`);
  };
  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <MobileHeader />
      <Header />
      <ScrollArrow />
      <div className="container">
        <div className="top_part ">
          <Link to="/" onClick={handleBack} className="back_btn">
            <IoMdArrowRoundBack />
            Go Back
          </Link>
        </div>
      </div>
      <section
        className="row_am pricing_section white_text aos-init aos-animate"
        data-aos-duration={1500}
      >
        <div className="pricing_inner  pt-5">
          <div className="container">
            <div className="dotes_blue">
              <img src="images/blue_dotes.png" alt="" />
            </div>
            {/* Tabing Details */}
            <animated.div style={springProps}>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="monthly"
                  role="tabpanel"
                  aria-labelledby="monthly-tab"
                >
                  {/* pricing box  monthly start */}
                  <div className="pricing_pannel mt-5">
                    <div className="row">
                      <div className="col-md-3">
                        <div
                          className="pannel_block aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-duration={1500}
                        >
                          <div className="heading text-center">
                            <h3>Buying Requests</h3>
                          </div>
                          <div className="vertical-scroller-container">
                            <ul className="vertical-scroller text-dark">
                              {currencies &&
                                currencies.map((currency, index) => (
                                  <li
                                    className="buy-currency-scroll  mt-2"
                                    key={index}
                                  >
                                    <span>
                                    {currency.currencycharacter}{" "}
                                    {currency.currencynumber}
                                    </span>
                                    
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="pannel_block aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-duration={1500}
                        >
                          <div className="heading text-center">
                            <h3>Sell</h3>
                          </div>
                          <div className="btn_block mt-5">
                            <Link
                              to="/sell-currency"
                              className="btn puprple_btn  buy-btn ml-0"
                            >
                              Currency
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block mt-4">
                            <Link
                              to="/sell-coin"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Coin
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block  mt-4">
                            <Link
                              to="/sell-Stamp"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Stamp
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block mt-4">
                            <Link
                              // onClick={() => handleComingSoonClick("Other")}
                              to="/sell-others"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Other
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="pannel_block aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-duration={1500}
                        >
                          <div className="heading text-center">
                            <h3>Buy</h3>
                          </div>
                          <div className="btn_block mt-5">
                            <Link
                              to="/buy-currency"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Currency
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block mt-4">
                            <Link
                              to="/buy-coin"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Coin
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block mt-4">
                            <Link
                              to="/buy-Stamp"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Stamp
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                          <div className="btn_block mt-4">
                            <Link
                              // onClick={() => handleComingSoonClick("Other")}
                              to="/buy-other"
                              className="btn puprple_btn buy-btn ml-0"
                            >
                              Other
                            </Link>
                            <div className="btn_bottom" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="pannel_block aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-duration={1500}
                        >
                          <div className="heading text-center">
                            <h3>Selling Requests</h3>
                          </div>
                          <div className="vertical-scroller-container">
                            <ul className="vertical-scroller text-dark">
                              {sellCurrencies &&
                                sellCurrencies.map((currency, index) => (
                                  <li
                                    className="buy-currency-scroll mt-2"
                                    key={index}
                                  >
                                    <span>
                                    {currency.Currencycharacter}{" "}
                                    {currency.CurrencyNo}
                                    <br />
                                    Face Value: {currency.FaceValue}
                                    <br />
                                    Base Value: {currency.BaseValue}
                                    <br />
                                    Value Plus: {currency.ValuePlus}
                                    </span>
                                   
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      
                        <div
                          className="tab-pane fade"
                          id="yearly"
                          role="tabpanel"
                          aria-labelledby="yearly-tab"
                        >
                          {/* pricing box yearly end */}
                        </div>
                      </div>
                    </div>
                    <section className="marquee mt-3">
                      <div className="container">
                        <div className="row text-center">
                          <div className="col-lg-12">
                            <div className="btn_block mt-5">
                              <Link
                                to="#"
                                id="assist-plus-btn"
                                className="btn puprple_btn    buy-btnn ml-0"
                              >
                                Buy Assist Plus Service?
                              </Link>
                              <div className="btn_bottom" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </animated.div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
