import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import image from '../images/blue_dotes.png.png';
import image1 from '../images/features.jpg.jpg';
import image2 from '../images/bigstar.png.png';
import image3 from '../images/smallStar.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from '../components/Footer';
import ScrollArrow from '../components/ScrollArrow';
import Loader from '../components/Loader';
import { MobileHeader } from '../components/MobileHeader';
import { useAuth } from '../context/AuthContext';
import { setAuthToken } from '../utils/authUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export const Login = () => {
    const [loading, setLoading] = useState(true);
    const [identifierError, setIdentifierError] = useState('');
    const [password, setPassword] = useState('');
    const [identifier, setIdentifier] = useState(''); 
    const [resData, setResData] = useState([]);
    const [passwordError, setPasswordError] = useState('');
     const [loginSuccess, setLoginSuccess] = useState(false); 
    const [rememberMe, setRememberMe] = useState(false); // State to remember checkbox
    const { isLoggedIn, setIsLoggedIn, login } = useAuth();
    const navigate = useNavigate();

    const validateIdentifier = () => {
        if (!identifier) {
            setIdentifierError('Please enter your email, customer ID, or phone number');
        } else {
            setIdentifierError('');
        }
    };

    const validatePassword = () => {
        if (!password) {
            setPasswordError('Password is required');
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateIdentifier();
        validatePassword();
    
        // If both email and password are provided, proceed with login
        if (identifier && password) {
            try {
                let loginType;
                // Check if the identifier is an email
                if (identifier.includes('@')) {
                    loginType = 'email';
                }
                // Check if the identifier starts with "SC" followed by digits (assuming it's a customer ID)
                else if (/^SC\d+$/.test(identifier)) {
                    loginType = 'customer_id';
                }
                // Check if the identifier consists of only numbers (assuming it's a phone number)
                else if (/^\d+$/.test(identifier)) {
                    // Assuming customer IDs are numeric and have a specific length, adjust this condition accordingly
                    if (identifier.length === 10) {
                        loginType = 'phone_number';
                    } else {
                        // If it doesn't match any known format, handle it as an invalid identifier
                        toast.error("Invalid Credentials");
                        return;
                    }
                }
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                    identifier: identifier,
                    password: password,
                    loginType: loginType // Sending loginType to the backend
                });
    
    
                if (response.data.isOK === true) {
                    toast.success("Login Successfully");
                    // setIsLoggedIn(true);
                    setResData(response.data)

                   
    
                    // Store email and password in localStorage if "Remember" is checked
                    if (rememberMe) {
                        localStorage.setItem('rememberedIdentifier', identifier);
                        localStorage.setItem('rememberedPassword', password);
                    } else {
                        // Clear remembered credentials if not checked
                        localStorage.removeItem('rememberedIdentifier');
                        localStorage.removeItem('rememberedPassword');
                    }
    
                    setLoginSuccess(true);
                } else {
                    console.error('Login failed');
                    // Handle login failure
                    toast("Login Failed");
                }
            } catch (error) {
                console.error('Error:', error.message);
                // Handle errors
                toast("Invalid Credentials");
                setIdentifierError('Enter Valid Username');
                setPasswordError('Enter Valid Password');
            }
        }
    };
    
    useEffect(() => {
        if (loginSuccess) {
            setTimeout(() => {
                navigate('/services');
                setIsLoggedIn(true);
                login(resData.data)
                const authToken = resData.token
                setAuthToken(authToken);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [loginSuccess, navigate]);
    useEffect(() => {
        // Check localStorage for remembered credentials
        const rememberedIdentifier = localStorage.getItem('rememberedIdentifier');
        const rememberedPassword = localStorage.getItem('rememberedPassword');
        if (rememberedIdentifier && rememberedPassword) {
            setIdentifier(rememberedIdentifier);
            setPassword(rememberedPassword);
        }

        // Simulate data loading delay (remove this in your actual code)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <MobileHeader />
            <Header />
            <ScrollArrow />
            <div className="full_bg">
                <section className="signup_section">
                    <div className="container">
                        <div className="top_part mt-5">
                            <Link to="/" className="back_btn"><IoMdArrowRoundBack />Go Back</Link>
                        </div>

                        <div className="form_block">
                            <div className="form_side">
                                <div className="section_title">
                                    <span className="title_badge">Welcome </span>
                                    <h2><span>Log in</span> to your account</h2>
                                    <p>Quickly access your products and features.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                    <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email, Customer ID, or Phone Number"
                                            value={identifier}
                                            onChange={(e) => setIdentifier(e.target.value)}
                                            onBlur={validateIdentifier}
                                        />
                                        {identifierError && <div className="error-message text-danger">{identifierError}</div>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={validatePassword}
                                        />
                                        {passwordError && <div className="error-message text-danger">{passwordError}</div>}
                                    </div>

                                    <div className="forgate_check">
                                        <div className="coustome_checkbox">
                                            <label htmlFor="remember_check">
                                                <input
                                                    type="checkbox"
                                                    id="remember_check"
                                                    checked={rememberMe}
                                                    onChange={() => setRememberMe(!rememberMe)} // Toggle rememberMe state
                                                />
                                                <span className="checkmark" />
                                                Remember
                                            </label>
                                            <label htmlFor="remember_check">
                                                <Link className='ms-5' to='/forgot-password'>Forgot Password?</Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="btn_block">
                                        <button type="submit" className="btn puprple_btn ml-0">Log in</button>
                                        <div className="btn_bottom" />
                                    </div>

                                    <div className="sign_in_here">
                                        <p>Don’t have an account? <Link to="/register">Sign Up here</Link></p>
                                    </div>
                                </form>
                            </div>
                            <div className="side_screen">
                                <div className="dotes_blue"><img src={image} alt="image" /></div>
                                <div className="left_icon">
                                    <img src={image3} alt="image" />
                                </div>
                                <div className="right_icon">
                                    <img src={image2} alt="image" />
                                </div>
                                <div className="scrren">
                                    <img src={image1} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};
