import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { HomeSlider } from "../components/HomeSlider";
import { AssurePlus } from "../components/AssurePlus";
import { Reviews } from "../components/Reviews";
import { Footer } from "../components/Footer";
import Chooseus from "../components/Chooseus";
import ScrollArrow from "../components/ScrollArrow";
import Loader from "../components/Loader";
import Franchise from "../components/Franchise";
import { MobileHeader } from "../components/MobileHeader";


export const Home = ({ setIsLoggedIn }) => {
  
 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    
  }, []);

 

  return (
    <>
      {loading && <Loader />}
      <MobileHeader />
      <Header setIsLoggedIn={setIsLoggedIn} />
      <HomeSlider />
      <Franchise />
      <AssurePlus />
      <Reviews />
      <Chooseus />
      <Footer />
      <ScrollArrow />

     
    </>
  );
};
