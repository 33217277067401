import React from 'react';

const Loader = () => {
  return (
    
     <div id="preloader">
     <div id="loader"></div>
 </div>
  );
};

export default Loader;
