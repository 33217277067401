import React from 'react'
import img1 from '../images/features.jpg.jpg'
import img2 from '../images/blue_dotes.png.png'
import img3 from '../images/personal.png.png'
export const AssurePlus = () => {
    return (
        <>
            <section className="row_am task_app_section">
               
                <div className="task_block" style={{ top: 120 }}>
                    <div className="dotes_blue"><img src={img2} alt="image" /></div>
                 
                    <div className="row">
                        <div className="col-md-6">
                          
                            <div className="task_img aos-init aos-animate" data-aos="fade-in" data-aos-duration={1500}>
                                <div className="frame_img">
                                    <img src={img1} alt="image" />
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="task_text">
                                <div className="section_title white_text aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                                    <span className="title_badge">Unique Features</span>
                                    <span className="icon">
                                        <img src={img3} alt="image" />
                                    </span>
                                    <h2>
                                      VALUE +
                                    </h2>
                                   
                                    <p>
                                       Personalized Assistance Will Be Appointed With Assure+ Guarantee of Each Currency Note Buyers Matching
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </section>

        </>
    )
}
