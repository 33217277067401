// utils/authUtils.js

// Function to set auth token in local storage
export const setAuthToken = (token) => {
    localStorage.setItem('scanncyToken', token);
    // Set a timeout to remove the token after 2 hours
    setTimeout(() => {
      localStorage.removeItem('scanncyToken');
    }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
  };
  
  // Function to get auth token from local storage
  export const getAuthToken = () => {
    return localStorage.getItem('scanncyToken');
  };
  