import React, { useState } from 'react'
import logo from '../images/logo.png.png'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};
export const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <header className='mobile-header'>
      
        <div className="container">
         
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to='/'>
              <img src={logo} alt="Logo" />
            </Link>
            <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="navbar-toggler-icon">
              <span className="toggle-wrap">
                <span className="toggle-bar" />
              </span>
            </span>
          </button>
          <motion.div
            className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ''}`}
            id="navbarSupportedContent"
            initial={false}
            animate={isOpen ? "open" : "closed"}
            variants={variants}
          >
            <ul className="navbar-nav ml-auto text-white">
              <li className="nav-item">
                <Link className="nav-link text-dark" to='/'>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark" to="#">About us</Link>
              </li>
              <li className="nav-item">
                <div className="btn_block">
                  <Link className="nav-link dark_btn" to='/login'>Login / Signup</Link>
                  <div className="btn_bottom" />
                </div>
              </li>
            </ul>
          </motion.div>
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto text-white">

                <li className="nav-item  ">
                  <Link className="nav-link text-white" to='/'>Home</Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link text-white" to="#">About us</Link>
                </li>

                <li className="nav-item">
                  <div className="btn_block">
                    <Link className="nav-link dark_btn" to='/login'>Login / Signup</Link>
                    <div className="btn_bottom" />
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          
        </div>
       
      </header>

    </>
  )
}
