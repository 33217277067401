import './App.css';
import './styles/new-home.css'
import './styles/style.css'
import './styles/aos.css'
import './styles/counter.css'
import './styles/lightcase.min.css'
import './styles/icofont.min.css'
import './styles/responsive.css'
import './styles/log-in.css'
import './styles/new-responsive.css'
import { Home } from './pages/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Services } from './pages/Services';
import   { Buy_Currency }  from './pages/Buy_Currency';
import ScrollToTop from './components/ScrollToTop';
import { useAuth } from './context/AuthContext';
import ForgotPassword from './pages/ForgotPassword';
import UserProfile from './pages/UserProfile';
import Portfolio from './pages/Portfolio';
import { useEffect, useState } from 'react';
import Sell_Currency from './pages/Sell_Currency';
import Sell_Coin from "./pages/Sell_Coin";
import ContactUs from './pages/ContactUs';
import { Buy_Coin } from './pages/Buy_Coin';
import Sell_Stamp from './pages/Sell_Stamp';
import { Buy_Stamp } from './pages/Buy_Stamp';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Sell_Other from './pages/Sell_Other';
import { Buy_Other } from './pages/Buy_Other';
function App() {
  
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [isLoggedInCheck, setIsLoggedCheck]  = useState(true);

  useEffect(() =>{
    setIsLoggedCheck(isLoggedIn);
  },[isLoggedIn]);



  return (
    <Router>
    <div className="">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/register"
          element={isLoggedIn ? <Navigate to="/" /> : <Register />}
        />
        <Route
          path="/forgot-password"
          element={isLoggedIn ? <Navigate to="/" /> : <ForgotPassword />}
        />
        {/* Private routes */}
        <Route
          path="/services"
          element={isLoggedIn ? <Services /> : <Navigate to="/login" />}
        />
        <Route
          path="/sell-currency"
          element={isLoggedIn ? <Sell_Currency /> : <Navigate to="/login" />}
        />
        <Route
          path="/sell-coin"
          element={isLoggedIn ? <Sell_Coin /> : <Navigate to="/login" />}
        />
        <Route
          path="/buy-coin"
          element={isLoggedIn ? <Buy_Coin /> : <Navigate to="/login" />}
        />
        <Route
          path="/sell-Stamp"
          element={isLoggedIn ? <Sell_Stamp /> : <Navigate to="/login" />}
        />
        <Route
          path="/buy-Stamp"
          element={isLoggedIn ? <Buy_Stamp /> : <Navigate to="/login" />}
        />
        <Route
          path="/privacy-policy" element={<PrivacyPolicy />}
        />
        <Route
          path="/buy-currency"
          element={isLoggedIn ? <Buy_Currency /> : <Navigate to="/login" />}
        />
        <Route
          path="/sell-others"
          element={isLoggedIn ? <Sell_Other /> : <Navigate to="/login" />}
        />
        <Route
          path="/buy-other"
          element={isLoggedIn ? <Buy_Other /> : <Navigate to="/login" />}
        />
        <Route
          path="/user-profile"
          element={isLoggedIn ? <UserProfile /> : <Navigate to="/login" />}
        />
        <Route
          path="/my-portfolio"
          element={isLoggedIn ? <Portfolio /> : <Navigate to="/login" />}
        />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
