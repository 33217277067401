import React, { useEffect, useState } from "react";
import image from "../images/blue_dotes.png.png";
import image1 from "../images/features.jpg.jpg";
import image2 from "../images/bigstar.png.png";
import image3 from "../images/smallStar.png";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { useSpring, animated } from "react-spring";
import ScrollArrow from "../components/ScrollArrow";
import Loader from "../components/Loader";
import { Header } from "../components/Header";
import { MobileHeader } from "../components/MobileHeader";
import axios from "axios";
export const Register = () => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [buyer, setIsBuyer] = useState(false);
  const [seller, setIsSeller] = useState(false);
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [buyerSellerError, setBuyerSellerError] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false); 
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Mobile validation
    if (!mobile) {
      setMobileError("Mobile is required");
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileError(
        "Mobile must be exactly 10 digits"
      );
      isValid = false;
    } else {
      setMobileError("");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Email is not valid");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Password validation
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }

    // Birthdate validation
    if (!birthdate) {
      setBirthdateError("Birthdate is required");
      isValid = false;
    } else {
      setBirthdateError("");
    }

    // Pincode validation
    if (!pincode) {
      setPincodeError("Pincode is required");
      isValid = false;
    } else {
      const pincodePattern = /^\d{6}$/; // Assuming pincode is a 6-digit number
      if (!pincodePattern.test(pincode)) {
        setPincodeError("Pincode must be a 6-digit number");
        isValid = false;
      } else {
        setPincodeError("");
      }
    }

    // City validation
    if (!city) {
      setCityError("City is required");
      isValid = false;
    } else {
      setCityError("");
    }

    // District validation
    if (!district) {
      setDistrictError("District is required");
      isValid = false;
    } else {
      setDistrictError("");
    }

   
    // Buyer/Seller validation
    if (!buyer && !seller) {
      setIsBuyer(false);
      setIsSeller(false);
      setBuyerSellerError("Please select at least one option (Buyer/Seller)");
      isValid = false;
    } else {
      setBuyerSellerError("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    const isValid = validateForm();
    e.preventDefault();
    console.log('clickk')

    if (isValid) {
    

      const formData = {
        name: name,
        mobile_no: mobile,
        email: email,
        password: password,
        birthDate: birthdate,
        anniversaryDate: anniversaryDate,
        buyer: buyer,
        seller: seller,
        pincode: pincode,
        city: city,
        district: district,
        address: address,
        IsActive: true,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/add/addUser`;

      try {
        const response = await axios.post(apiUrl, formData);

        if (response.data.isOK === true) {
          toast.success('Registration Success')
          setName("");
          setMobile("");
          setEmail("");
          setPassword("");
          setBirthdate("");
          setIsBuyer(false);
          setIsSeller(false);
          setPincode("");
          setCity("");
          setDistrict("");
          setAddress("");
          setLoginSuccess(true);
        }
        if (response.data.isOK === false) {
          setEmailError(response.data.message.email);
          setMobileError(response.data.message.mobile_no);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      console.error("Form has errors");
    }
  };
  useEffect(() => {
    if (loginSuccess) {
        setTimeout(() => {
            navigate('/login');
           
        }, 3000); // 3000 milliseconds = 3 seconds
    }
}, [loginSuccess, navigate]);
  useEffect(() => {
    const fetchCityAndDistrict = async () => {
      if (pincode) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${pincode}`
          );
          if (
            response.data &&
            response.data[0] &&
            response.data[0].PostOffice &&
            response.data[0].PostOffice.length > 0
          ) {
            const postOffice = response.data[0].PostOffice[0];
            setDistrict(postOffice.District);
            setCity(postOffice.Block);
          } else {
            setCity("");
            setDistrict("");
          }
        } catch (error) {
          console.error("Error fetching city and district:", error);
        }
      }
    };

    fetchCityAndDistrict();
  }, [pincode]);

  useEffect(() => {
    // Simulate data loading delay (remove this in your actual code)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust duration as needed
      once: true, // Scroll animation occurs only once
    });
  }, []);

  const springProps = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
  });

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <MobileHeader />
      <Header />
      <ScrollArrow />
      <div className="full_bg">
        <section className="signup_section">
          <div className="container">
            <div className="top_part mt-5">
              <Link to="/" className="back_btn">
                <IoMdArrowRoundBack />
                Go Back
              </Link>
            </div>
            <animated.div style={springProps}>
              <div className="form_block">
                <div className="form_side">
                  <div className="section_title">
                    <span className="title_badge mt-5">Get Started</span>
                    <h2>
                      <span>Create</span> an account
                    </h2>
                    <p>
                      Fill all fields so we can get some info about you. We'll
                      never send you spam
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {nameError && (
                        <div className="error-message text-danger">
                          {nameError}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile*"
                        value={mobile}
                        maxLength={10}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                      {mobileError && (
                        <div className="error-message text-danger">
                          {mobileError}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && (
                        <div className="error-message text-danger">
                          {emailError}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password*"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError && (
                        <div className="error-message text-danger">
                          {passwordError}
                        </div>
                      )}
                    </div>
                    <div className="form-group d-flex gap-3">
                      <div>
                        <input
                          type="text"
                          className="form-control  w-75"
                          placeholder="Pincode*"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                        />
                        {pincodeError && (
                          <div className="error-message text-danger">
                            {pincodeError}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="City*"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        {cityError && (
                          <div className="error-message text-danger">
                            {cityError}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control  "
                          placeholder="District*"
                          value={district}
                          onChange={(e) => setDistrict(e.target.value)}
                        />
                        {districtError && (
                          <div className="error-message text-danger">
                            {districtError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Address/Area"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <div className="text-dark">
                        What You Want to Become* :{" "}
                        <span>
                          <input
                            type="checkbox"
                            id="buyerCheckbox"
                            checked={buyer}
                            onChange={() => setIsBuyer(!buyer)}
                          />{" "}
                          Buyer{" "}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            id="sellerCheckbox"
                            checked={seller}
                            onChange={() => setIsSeller(!seller)}
                          />{" "}
                          Seller
                        </span>
                        {buyerSellerError && (
                          <div className="error-message text-danger">
                            {buyerSellerError}
                          </div>
                        )}
                      </div>
                    </div>
                    <h5 className="mb-3">Date to Remember</h5>
                    <div className="form-group">
                      <label>Birthdate*</label>
                      <input
                        type="date"
                        className="form-control"
                        value={birthdate}
                        onChange={(e) => setBirthdate(e.target.value)}
                      />
                      {birthdateError && (
                        <div className="error-message text-danger">
                          {birthdateError}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Anniversary Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={anniversaryDate}
                        onChange={(e) => setAnniversaryDate(e.target.value)}
                      />
                    </div>

                    <div className="btn_block">
                      <button type="button" onClick={handleSubmit} className="btn puprple_btn ml-0">
                        Sign Up Now
                      </button>
                      <div className="btn_bottom" />
                    </div>
                    <div className="sign_in_here mb-5 mt-3">
                      <p>
                        Already have an account?{" "}
                        <Link to="/login">Sign In here</Link>
                      </p>
                    </div>
                  </form>
                </div>
                <div className="side_screen">
                  <div className="dotes_blue">
                    <img src={image} alt="" />
                  </div>
                  <div className="left_icon">
                    <img src={image3} alt="" />
                  </div>
                  <div className="right_icon">
                    <img src={image2} alt="" />
                  </div>
                  <div className="scrren">
                    <img src={image1} alt="" />
                  </div>
                </div>
              </div>
            </animated.div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
