import React, { useState, useEffect } from 'react';
import axios from 'axios';
const Franchise = () => {
  const [text, setText] = useState("");



  useEffect(() => {
    const fetchText = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/get/allCms`); 
        const allCms = response.data.allCms;
    
    // Filter CMS items by title
    const supportFranchiseCms = allCms.filter(cms => cms.title.trim().toLowerCase() === "support franchise");

    // Check if there are CMS items with the title "Support Franchise"
    if (supportFranchiseCms.length > 0) {
      // Extract descriptions from filtered CMS items
      const supportFranchiseDescriptions = supportFranchiseCms.map(cms => stripHtmlTags(cms.description));
      setText(supportFranchiseDescriptions)
    } else {
      console.error("No CMS items found with the title 'Support Franchise'");
    }
        // setText(filteredCms.title);
      } catch (error) {
        console.error('Error fetching text:', error);
      }
    };

    fetchText();
  }, []); 
  // Function to repeat the text
  const repeatText = (count) => {
    let repeatedText = "";
    for (let i = 0; i < count; i++) {
      repeatedText += `${text} `;
    }
    return repeatedText;
  };
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }
  
  return (
    <div className="choose-us container">
      <div className="text_list_section row_am aos-init aos-animate" data-aos="fade-in" data-aos-duration="3000">
        <div className="container mt-0">
          <span className="title_badge down_fix">Support Franchise</span>
        </div>
        <div className="slider_block">
          <div className="item">
            <div className="text_block">
              <span className='slide-block'>{repeatText(1)}</span> {/* Adjust the number inside repeatText() to repeat the text */}
              <span className="mark_star">•</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Franchise;
