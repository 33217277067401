import React, { useState, useEffect,useRef } from "react";
import axios from "axios";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import image from "../images/blue_dotes.png.png";
import image1 from "../images/features.jpg.jpg";
import image2 from "../images/bigstar.png.png";
import image3 from "../images/smallStar.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import ScrollArrow from "../components/ScrollArrow";
import Loader from "../components/Loader";
import { MobileHeader } from "../components/MobileHeader";
import { useAuth } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Sell_Other = () => {
  const [loading, setLoading] = useState(true);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [isSpecialNumber, setIsSpecialNumber] = useState(false);
  const [faceValue, setFaceValue] = useState("");
  const [askValue, setAskValue] = useState("");
  const [description, setDescription] = useState("");
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});
  const frontFileInputRef = useRef(null); // Create a ref for the front image file input
  const backFileInputRef = useRef(null); // Create a ref for the back image file input

  const { user } = useAuth();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1)
  };
  useEffect(() => {
 
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (toastDisplayed) {
      const timer = setTimeout(() => {
        navigate("#"); // Navigate to '/' after 3000 milliseconds (3 seconds)
      }, 3000);

      return () => clearTimeout(timer); // Clear the timer on component unmount
    }
  }, [toastDisplayed, navigate]);
  // Function to handle form submission
  const handlSellData=async()=>{
    if (Object.keys(errors).length === 0) {
      
      try {
        const formData = new FormData();
        formData.append("customerId", user.customerId);
        formData.append("CostomerName", user.name);

        formData.append("isSpecialNumber", isSpecialNumber);
        formData.append("FaceValue", faceValue);
        formData.append("AskValue", askValue);
        formData.append("Description", description);
        formData.append("PhotoFront", frontImage);
        formData.append("PhotoBack", backImage);
       
        formData.append("IsActive", true);

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/create/OtherSell`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // You can handle success behavior here (e.g., show a success message)
        resetForm();
        toast.success(
          "Data Submited Successfully Our Executive will contact you soon.."
        );
        setToastDisplayed(true); // Set toast displayed state
      } catch (error) {
        console.error("An error occurred:", error);
      
      }
    } else {
      setErrors(errors);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    // setConfirmModal(true)
    if (Object.keys(errors).length === 0) {
      
      try {
        const formData = new FormData();
        formData.append("customerId", user.customerId);
        formData.append("CostomerName", user.name);
        formData.append("CostomerEmail", user.email);
        formData.append("CostomerNumber", user.mobile_no);
        formData.append("CostomerPincode", user.pincode);

        formData.append("isSpecialNumber", isSpecialNumber);
        formData.append("FaceValue", faceValue);
        formData.append("AskValue", askValue);
        formData.append("Description", description);
        formData.append("PhotoFront", frontImage);
        formData.append("PhotoBack", backImage);
       
        formData.append("IsActive", true);

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/create/OtherSell`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // You can handle success behavior here (e.g., show a success message)
        resetForm();
        toast.success(
          "Data Submited Successfully Our Executive will contact you soon.."
        );
        setToastDisplayed(true); // Set toast displayed state
      } catch (error) {
        console.error("An error occurred:", error);
      
      }
    } else {
      setErrors(errors);
    }
  };
  const resetForm = () => {
    setIsSpecialNumber(false);
    setFaceValue("");
    setAskValue("");
    setDescription("");
    setFrontImage(null);
    setBackImage(null);
    frontFileInputRef.current.value = ''; // Reset the value of the front image input to clear the selected file
    backFileInputRef.current.value = ''; // Reset the value of the back image input to clear the selected file
   
    setErrors({});
  };
  // Function to validate form fields
  const validateForm = () => {
    const errors = {};

    if (!faceValue) {
      errors.faceValue = "Face value is required";
    }

    if (!askValue) {
      errors.askValue = "Ask value is required";
    }

    if (!description) {
      errors.description = "Description is required";
    }

    if (!frontImage) {
      errors.frontImage = "Front image is required";
    }

    if (!backImage) {
      errors.backImage = "Back image is required";
    }



    return errors;
  };
  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <MobileHeader />
      <Header />
      <ScrollArrow />
      <div className="full_bg">
        <section className="signup_section">
          <div className="container">
            <div className="top_part mt-5">
              <Link to="/" onClick={handleBack} className="back_btn">
                <IoMdArrowRoundBack />
                Go Back
              </Link>
            </div>
            <div className="form_block">
              <div className="form_side">
                <div className="section_title">
                  <span className="title_badge mt-5">Get Started</span>
                  <h2>
                    <span>Submit </span>Other
                  </h2>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="form-group mb-1 d-flex align-items-center">
                    <label>Face Value*</label>
                  
                   
                    <input
                      type="text"
                      className="form-control w-50 ms-3"
                      placeholder="Enter amount"
                      value={faceValue}
                      onChange={(e) => setFaceValue(e.target.value)}
                      required
                    />
                  </div>
                  {errors.faceValue && (
                      <div className="error-message text-danger">
                        {errors.faceValue}
                      </div>
                    )}
                  <br />
                  <div className="form-group mb-1 d-flex align-items-center">
                    <label>Ask Value*</label>
                    <input
                      type="text"
                      className="form-control w-50 ms-3"
                      placeholder="Enter amount"
                      value={askValue}
                      onChange={(e) => setAskValue(e.target.value)}
                      required
                    />
                  </div>
                  {errors.askValue && (
                      <div className="error-message text-danger">
                        {errors.askValue}
                      </div>
                    )}
                  <br />
                  <div className="row form-group mt-1">
                    <div className="col-lg-6">
                      <label htmlFor="myfile">Other photo Front*</label>
                    </div>
                    <div className="col-lg-6">
                      <input
                     ref={frontFileInputRef}
                        className="text-dark"
                        type="file"
                        id="myfile"
                        name="myfile"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => setFrontImage(e.target.files[0])}
                        required
                      />
                      {errors.frontImage && (
                        <div className="error-message text-danger">
                          {errors.frontImage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row form-group mt-1">
                    <div className="col-lg-6">
                      <label htmlFor="myfile">Other photo  Back*</label>
                    </div>
                    <div className="col-lg-6">
                      <input
                      ref={backFileInputRef}
                        className="text-dark"
                        type="file"
                        id="myfile"
                        name="myfile"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => setBackImage(e.target.files[0])}
                        required
                      />
                      {errors.backImage && (
                        <div className="error-message text-danger">
                          {errors.backImage}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="face-value">Description*</label>
                  
                    <textarea 
                      type="text"
                      rows="1"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                    {errors.description && (
                      <div className="error-message text-danger">
                        {errors.description}
                      </div>
                    )}
                  </div>
             
                
                  <div className="forgate_check"></div>
                  <div className="btn_block mb-4">
                    <button type="submit" className="btn puprple_btn ml-0">
                      Submit
                    </button>
                    <div className="btn_bottom" />
                  </div>
                </form>
              </div>
              <div className="side_screen">
                <div className="dotes_blue">
                  <img src={image} alt="" />
                </div>
                <div className="left_icon">
                  <img src={image3} alt="" />
                </div>
                <div className="right_icon">
                  <img src={image2} alt="" />
                </div>
                <div className="scrren">
                  <img src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      {confirmModal && (
                <div className="modal-1" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Search Result</h5>
                                <button type="button" className="btn-close" onClick={() => setConfirmModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                               <h6>Face Value : {faceValue}</h6>
                               <h6>Premium Value : {parseInt(faceValue) + 200}</h6>
                               <h6>Value +A : {faceValue}</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"onClick={() => setConfirmModal(false)}>NO</button>
                                <button type="button" className="btn btn-secondary" onClick={handlSellData}>YES</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </>
  );
};

export default Sell_Other;
