import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Header } from "../components/Header";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Footer } from "../components/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UserProfile = () => {
  const { user,setIsLoggedIn } = useAuth();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1)
  };
  const handlePasswordChange = async () => {
    try {
      // Validation
      if (!oldPassword || !newPassword || !confirmPassword) {
        setErrorMessage("All fields are required");
        return;
      }
      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        return;
      }

      // API call to change password
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/change-password`, {
        email: user.email,
        oldPassword,
        password: newPassword,
      });

      if (response.data.isOk === true) {
        setErrorMessage("");
        setConfirmPassword("");
        setNewPassword("");
        setOldPassword("");
        setSuccessMessage(response.data.message);
        toast.success(
          "Password Changed"
        );
        localStorage.removeItem("scanncyToken");
        setIsLoggedIn(false);
      } else {
        setErrorMessage(response.data.message || "Failed to change password");
      }
    } catch (error) {
      setErrorMessage("Failed to change password");
    }
  };
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, navigate]);
  return (
    <>
      <Header />
      <div className="top_part mb-3 container">
              <Link to="/" onClick={handleBack} className="back_btn">
                <IoMdArrowRoundBack />
                Go Back
              </Link>
            </div>
      <div className="container  border-0 p-5 " style={{background:'#181a22', borderRadius:'12px'}}>
      
        <div className="row">
          <div className="col-lg-6">
            <div className="user-profile   p-3 ">
              <h2>User Profile</h2>

              <div class="row g-3 align-items-center mt-2">
                <div class="col-3">
                  <label for="inputPassword6" class="col-form-label">
                   Costomer Id
                  </label>
                </div>
                <div class="col-9">
                  <input
                    disabled
                    type="text"
                    value={user&&user.customerId}
                    id="inputPassword6"
                    class="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
                <div class="col-3">
                  <label for="inputPassword6" class="col-form-label">
                    Name
                  </label>
                </div>
                <div class="col-9">
                  <input
                    disabled
                    type="text"
                    value={user&&user.name}
                    id="inputPassword6"
                    class="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
                <div class="col-3">
                  <label for="inputPassword6" class="col-form-label">
                    Email
                  </label>
                </div>
                <div class="col-9">
                  <input
                    disabled
                    type="email"
                    value={user&&user.email}
                    id="inputPassword6"
                    class="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
                <div class="col-3">
                  <label for="inputPassword6" class="col-form-label">
                    Mobile Number
                  </label>
                </div>
                <div class="col-9">
                  <input
                    disabled
                    type="email"
                    value={user&&user.mobile_no}
                    id="inputPassword6"
                    class="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
                <div class="col-3">
                  <label for="inputPassword6" class="col-form-label">
                    BirthDate
                  </label>
                </div>
                <div class="col-9">
                  <input
                    disabled
                    type="email"
                    value={user&&user.birthDate}
                    id="inputPassword6"
                    class="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="change-password  p-3">
              <h2 >Change Password</h2>
              <div className="mt-4">
                <input
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="form-control w-50"
                  placeholder="Enter Old Password"
                />
                <div className="mt-3 gap-3 d-flex">
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="form-control w-50"
                    placeholder="Enter New Password"
                  />
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control w-50"
                    placeholder="Confirm New Password"
                  />
                </div>
                <div className="mt-3">
                  <button onClick={handlePasswordChange} className="btn btn-success">
                    Save
                  </button>
                </div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                {successMessage && <p className="text-success">{successMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserProfile;
